import Row from '@atoms/CustomGrid/Row';
import GridContainer from '@atoms/GridContainer/GridContainer';
import SocialShareButton from '@atoms/SocialShareButton/SocialShareButton';
import { Box, Button, Grid, Typography, Icon } from '@mui/material';
import theme from '@mui/theme';
import { removeHtmlTags } from '@utils/functions/utils';
import router from 'next/router';
import Image from 'next/image';
import styles from '@atoms/CustomCard/CustomCard.module.scss';
import dynamic from 'next/dynamic';
import MediaPlayerCard from '@atoms/MediaPlayerCard/MediaPlayerCard';
import { useTranslation } from 'next-i18next';

const CloseRoundedIcon = dynamic(() => import('@mui/icons-material/CloseRounded'));

const ExpandVideoContent = (props) => {
  const {
    component,
    videoUrl,
    imageUrl,
    imageAlt,
    isMuted,
    toggleMute,
    handleCloseExpandCard,
    handleCloseMobileModal,
    label,
    path,
    socials,
    abstract,
    tags,
  } = props;

  const isPopover = component == 'popover';
  const isModal = component == 'modal';
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        borderRadius: isPopover
          ? `${theme.spacing(8)} ${theme.spacing(8)} ${theme.spacing(0)} ${theme.spacing(0)}`
          : `${theme.spacing(0)} ${theme.spacing(8)} ${theme.spacing(8)} ${theme.spacing(0)}`,
        overflow: 'hidden',
        backgroundColor: isPopover && theme.palette.grey['900'],
        display: isModal && 'flex',
        flexDirection: isModal && 'column',
      }}
      className={styles.expandedVideoContent}
    >
      <Box position={'relative'}>
        {!videoUrl ? (
          <Box className={styles.CarouselCardImageBox} overflow="hidden" minHeight={theme.spacing(420)}>
            <Image
              style={{ objectFit: 'cover' }}
              className={styles.modalImageExploreCard}
              fill
              src={imageUrl}
              alt={imageAlt}
            />
          </Box>
        ) : (
          <>
            <MediaPlayerCard videoUrl={videoUrl} imageUrl={imageUrl} imageAlt={imageAlt} />
          </>
        )}
        <Box
          position={'absolute'}
          top={0}
          right={0}
          padding={isPopover ? `${theme.spacing(20)} ${theme.spacing(26)}` : theme.spacing(22)}
        >
          <Box
            className="video"
            borderRadius="50%"
            zIndex={1}
            width={theme.spacing(40)}
            height={theme.spacing(40)}
            display="flex"
            bgcolor={theme.palette.common.white}
            alignItems="center"
            justifyContent="center"
            marginLeft={theme.spacing(24)}
            onClick={isPopover ? handleCloseExpandCard : null}
            onTouchEnd={isModal ? handleCloseMobileModal : null}
            sx={{
              cursor: 'pointer',
              transition: 'background-color 250ms',
              '&:hover': {
                backgroundColor: theme.palette.grey['400'],
              },
            }}
          >
            <CloseRoundedIcon sx={{ color: theme.palette.primary.main, fontSize: theme.spacing(22), zIndex: 110 }} />
          </Box>
        </Box>
        <Box
          position={'absolute'}
          zIndex={999}
          bottom={0}
          padding={
            isModal
              ? `${theme.spacing(0)} ${theme.spacing(22)} ${theme.spacing(24)}`
              : `${theme.spacing(32)} ${theme.spacing(40)}`
          }
          maxHeight={isModal ? theme.spacing(707) : null}
          width={'100%'}
        >
          <Box marginBottom={isPopover ? theme.spacing(24) : theme.spacing(40)}>
            <Typography component="h3" color="white" variant="h3" fontWeight={500}>
              {label}
            </Typography>
          </Box>
          <Box display={'flex'} justifyContent={isModal && 'space-between'} marginRight={theme.spacing(72)}>
            <Button
              sx={{
                borderRadius: theme.spacing(8),
                backgroundColor: theme.palette.primary.main,
              }}
              onClick={() => router.push(path)}
              variant="small"
              color="primary"
              startIcon={<Icon>play_arrow</Icon>}
            >
              {t('explorerVideo.watchNow')}
            </Button>
            <Box
              className="video"
              borderRadius="50%"
              zIndex={1}
              width={theme.spacing(56)}
              height={theme.spacing(56)}
              display="flex"
              bgcolor={theme.palette.primary.main}
              alignItems="center"
              justifyContent="center"
              marginLeft={theme.spacing(24)}
              sx={{
                cursor: 'pointer',
                transition: 'background-color 250ms',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              <SocialShareButton socials={socials} color={theme.palette.common.white} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={isModal ? 'column' : 'row'}
        padding={
          isPopover
            ? `${theme.spacing(35)} ${theme.spacing(32)} ${theme.spacing(40)} ${theme.spacing(40)}`
            : `${theme.spacing(22)} ${theme.spacing(22)} ${theme.spacing(32)}`
        }
      >
        <GridContainer sx={{ padding: '0 !important' }}>
          <Row>
            {isPopover && (
              <>
                <Grid item lg={9}>
                  <Box>
                    <Typography component={'p'} variant="bodyL" color={theme.palette.common.white}>
                      {removeHtmlTags(abstract)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={3}>
                  <Box>
                    {tags && (
                      <Typography
                        component="span"
                        variant="caption"
                        fontSize={theme.spacing(10)}
                        textTransform={'uppercase'}
                        color={theme.palette.grey['500']}
                      >
                        {'Video:'}
                      </Typography>
                    )}
                    {tags &&
                      tags.map((tag, index) => (
                        <Typography
                          key={index + tag}
                          component="span"
                          variant="caption"
                          sx={{ marginLeft: theme.spacing(4) }}
                          fontSize={theme.spacing(10)}
                          textTransform={'uppercase'}
                          color={theme.palette.grey['500']}
                        >
                          {`#${tag}`}
                        </Typography>
                      ))}
                  </Box>
                </Grid>
              </>
            )}

            {isModal && (
              <Grid item xs={12} paddingLeft={'0 !important'}>
                <Box>
                  {tags && (
                    <Typography
                      component="span"
                      variant="caption"
                      fontSize={theme.spacing(10)}
                      textTransform={'uppercase'}
                      color={theme.palette.grey['500']}
                    >
                      {'Video:'}
                    </Typography>
                  )}
                  {tags &&
                    tags.map((tag, index) => (
                      <Typography
                        key={index + tag}
                        component="span"
                        variant="caption"
                        sx={{ marginLeft: theme.spacing(4) }}
                        fontSize={theme.spacing(10)}
                        textTransform={'uppercase'}
                        color={theme.palette.grey['500']}
                      >
                        {`#${tag}`}
                      </Typography>
                    ))}
                </Box>
              </Grid>
            )}
          </Row>
          {isModal && (
            <Row marginTop={theme.spacing(16)}>
              <Box>
                <Typography component={'p'} variant="bodyL" color={theme.palette.common.white}>
                  {removeHtmlTags(abstract)}
                </Typography>
              </Box>
            </Row>
          )}
        </GridContainer>
      </Box>
    </Box>
  );
};

export default ExpandVideoContent;
