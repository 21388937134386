import { PropsWithChildren } from 'react';
import { LinkChildrenProps } from '@atoms/LinkChildren/LinkChildren.props';
import Link from 'next/link';
import { addDomainTo } from '@utils/functions/addDomainTo';

const LinkChildren = (props: PropsWithChildren<LinkChildrenProps>) => {
  const { isExternal, target = '_self', children, url, rel, className = '' } = props;

  if (!url) return <div className={className}>{children}</div>;

  if (isExternal) {
    return (
      <a href={addDomainTo(url)} target={target} rel={rel} className={className}>
        {children}
      </a>
    );
  }

  return (
    <Link prefetch={false} href={url} rel={rel} className={className}>
      {children}
    </Link>
  );
};

export default LinkChildren;
