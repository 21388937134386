import { useState, useEffect } from 'react';

const useIsIos = () => {
  const [isIos, setIsIos] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined' || typeof navigator === 'undefined') return;
    const userAgent = navigator.userAgent || navigator.vendor;
    const isIosDevice =
      /iPad|iPhone|iPod/.test(userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    setIsIos(isIosDevice);
  }, []);

  return { isIos };
};

export default useIsIos;
