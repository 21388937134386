import { useState } from 'react';

import theme from '@mui/theme';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import ArrowOutward from '@mui/icons-material/ArrowOutward';
import Share from '@mui/icons-material/Share';
import SocialIcon from '@atoms/SocialIcon/SocialIcon';

import { SocialShareButtonProps } from '@atoms/SocialShareButton/SocialShareButton.props';

const SocialShareButton = (props: SocialShareButtonProps) => {
  const { socials, dataType, color } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        sx={{ color: color ? color : 'primary.main' }}
        aria-controls={open ? 'socials-menu' : undefined}
        aria-haspopup="true"
        id="share-button"
        aria-label="Socials"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Share />
      </IconButton>
      <Menu
        id="socials-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: dataType?.type == '2' ? (dataType?.url ? (isMobile ? 312 : 262) : isMobile ? 246 : 262) : 240,
          horizontal: dataType?.type == '2' ? (dataType?.url ? (isMobile ? 205 : 118) : isMobile ? 132 : 118) : 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'share-button',
        }}
      >
        {socials.map((social, index) => (
          <MenuItem
            sx={{ display: 'flex', gap: theme.spacing(16) }}
            style={{ color: theme.palette.primary.main }}
            key={index}
            onClick={social.onClick}
          >
            <SocialIcon name={social.icon} />
            <Typography variant="bodyM" color="common.black">
              {social.label}
            </Typography>
          </MenuItem>
        ))}

        {dataType?.type == '2' && isMobile && dataType?.url && <Divider sx={{ margin: '.3rem  1rem !important' }} />}
        {dataType?.type == '2' && isMobile && dataType?.url && (
          <MenuItem
            sx={{ display: 'flex', gap: theme.spacing(16) }}
            style={{ color: theme.palette.primary.main }}
            href={dataType?.url}
            component={Link}
          >
            <ArrowOutward />
            <Typography variant="bodyM" color="common.black">
              {dataType?.label}
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default SocialShareButton;
