export const addDomainTo = (srcAttribute) => {
  // Controlla se srcAttribute è uguale a uno dei domini specifici
  const allowedDomains = ['https://admin.webuildgroup.com', 'https://salini-pdf-archive.s3.eu-west-1.amazonaws.com'];

  if (!srcAttribute.includes('://')) {
    srcAttribute = process.env.NEXT_PUBLIC_IMG_BASE_URL + srcAttribute;
  } else if (allowedDomains.some((domain) => srcAttribute.startsWith(domain))) {
    srcAttribute = srcAttribute.replace(/^(https?:\/\/)?(www\.)?[^/]+/, process.env.NEXT_PUBLIC_IMG_BASE_URL);
  }

  return srcAttribute;
};
