import { SocialShare } from '@atoms/SocialShareButton/SocialShareButton.props';
import { useTranslation } from 'next-i18next';

export const useSocials = ({ url, title }): SocialShare[] => {
  const { t } = useTranslation();

  return [
    {
      label: 'Facebook',
      icon: 'facebook',
      onClick: () =>
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}&title=${title}`, '_blank', 'noopener'),
    },
    {
      label: 'LinkedIn',
      icon: 'linkedin',
      onClick: () =>
        window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`, '_blank', 'noopener'),
    },
    {
      label: 'Twitter',
      icon: 'twitter',
      onClick: () =>
        window.open(
          `https://twitter.com/intent/tweet?original_referer=${url}&text=${title}&url=${url}`,
          '_blank',
          'noopener'
        ),
    },
    {
      label: t('copyLink'),
      onClick: () => {
        navigator.clipboard.writeText(url);
      },
      icon: 'copy',
    },
  ];
};
