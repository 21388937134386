import { useMemo, useState } from 'react';
import { CustomCardProps } from '@atoms/CustomCard/CustomCard.props';
import Image from 'next/image';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useBreakpoint } from '@hooks/useBreakpoint';
import { parseHTML } from '@utils/functions/parseHTML';
import dayjs from 'dayjs';
import theme from '@mui/theme';
import audioLevel from '/public/assets/images/audio-level-lines.svg';
import audioLevelLarge from '/public/assets/images/audio-level-lines-large.svg';
import brandCardBackground from '/public/assets/images/brand-card-background.svg';
import logoWBMagazine from '/public/assets/images/Logo_WB_magazine.svg';
import { useRouter } from 'next/router';
import styles from './CustomCard.module.scss';
import 'dayjs/locale/it';
import 'dayjs/locale/en';

import dynamic from 'next/dynamic';
import { useMediaQuery } from '@mui/material';
import ExploreVideosCard from './Cards/ExploreVideosCard/ExploreVideosCard';

const Icon = dynamic(() => import('@mui/material/Icon'));
const Stack = dynamic(() => import('@mui/material/Stack'));
const EastIcon = dynamic(() => import('@mui/icons-material/East'));
const NorthEastIcon = dynamic(() => import('@mui/icons-material/NorthEast'));
const CustomSpreakerIcon = dynamic(() => import('@atoms/CustomSpreakerIcon'));
const CustomSpotifyIcon = dynamic(() => import('@atoms/CustomSpotifyIcon'));
const CustomAmazonIcon = dynamic(() => import('@atoms/CustomAmazonIcon'));
const CustomAppleIcon = dynamic(() => import('@atoms/CustomAppleIcon'));
const CustomButton = dynamic(() => import('@atoms/CustomButton/CustomButton'));
const RoundLabelIcon = dynamic(() => import('@atoms/RoundLabelIcon/RoundLabelIcon'));
const DocumentCardCaption = dynamic(() => import('@atoms/CustomCard/DocumentCardCaption/DocumentCardCaption'));

const CustomCard = (props: CustomCardProps) => {
  const {
    index,
    label,
    variant,
    title,
    caption,
    captionFormat,
    source,
    overlayBoxPadding,
    imageAlt,
    tagLabel,
    tagLabelText,
    tags,
    createdAtDate,
    sx = {},
    imageUrl,
    iconName,
    colorVariant,
    podcastIcon,
    businessIcon,
    isExternalLink,
    onClick,
    path,
    videoUrl,
    abstract,
  } = props;

  const router = useRouter();
  const { isLarger } = useBreakpoint('lg');
  const [zIndex, setZIndex] = useState(0);

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const image = useMemo(() => {
    return {
      url: imageUrl || '/assets/images/webuild.jpg',
      alt: imageAlt || title,
    };
  }, [imageUrl, imageAlt, title]);

  const formattedDate = useMemo(() => {
    if (!createdAtDate) return '';

    const formatted = createdAtDate.split('/').reverse().join('-');

    if (!dayjs(formatted).isValid()) {
      return '';
    }

    const formattedDate = dayjs(formatted).locale(router.locale).format('D MMMM YYYY');

    return formattedDate === 'Invalid Date' ? '' : formattedDate;
  }, [createdAtDate, router.locale]);

  const handleMouseOver = () => {
    setZIndex(998);
  };

  const handleMouseLeave = () => {
    setZIndex(1);
    setTimeout(() => setZIndex(0), 400);
  };

  const textVariant = useMemo(() => {
    return isLarger ? 'subtitle' : 'bodyM';
  }, [isLarger]);

  const platformIcons = {
    apple: <CustomAppleIcon className={styles.svg} />,
    amazon: <CustomAmazonIcon className={styles.svg} />,
    spotify: <CustomSpotifyIcon className={styles.svg} />,
    spreaker: <CustomSpreakerIcon className={styles.svg} />,
  };

  switch (variant) {
    case 'white-background-with-icon':
      return (
        <Box
          pt={{ xs: 11, lg: 31 }}
          px={{ xs: 11, lg: 23 }}
          pb={{ xs: 11, lg: 23 }}
          sx={sx}
          bgcolor="common.white"
          zIndex={zIndex}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          className={`${styles.WallCard} ${styles.Card}`}
          border="1px solid"
          borderColor="grey.200"
          borderRadius={theme.spacing(16)}
        >
          <RoundLabelIcon {...(isLarger && { variant: 'large' })} iconName={iconName} />

          <Typography
            mt={{ xs: 30, lg: 47 }}
            component="p"
            className="font-weight-bold"
            color="common.black"
            variant={textVariant}
          >
            {label}
          </Typography>
        </Box>
      );

    case 'document-card':
      return (
        <Box
          display="flex"
          borderRadius={theme.borderRadius.fullImageCard}
          overflow="hidden"
          alignItems="stretch"
          height="100%"
          bgcolor="common.white"
          position="relative"
          zIndex={1}
          gap={{ xs: theme.spacing(16), lg: theme.spacing(24) }}
          boxShadow={theme.boxShadows.elevationLight200}
          className={`${styles.Card} ${styles.DocumentCard} ${styles[tagLabel]}`}
        >
          <Box
            bgcolor="primary.main"
            position="absolute"
            top="calc(50% - 22px)"
            left={0}
            zIndex={1}
            width={theme.spacing(2)}
            height={theme.spacing(44.22)}
          />

          {isLarger && imageUrl && tagLabel != 'event' && (
            <Box
              sx={{ borderBottomRightRadius: theme.spacing(16) }}
              overflow="hidden"
              flexGrow={0}
              width={theme.spacing(191)}
              flexShrink={0}
              position="relative"
            >
              <Image
                src={imageUrl}
                alt={imageAlt || tagLabel}
                fill
                style={{ objectFit: 'cover' }}
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              />
            </Box>
          )}

          {formattedDate && tagLabel == 'event' && (
            <Box
              sx={{
                borderBottomRightRadius: theme.spacing(16),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              overflow="hidden"
              flexGrow={0}
              width={theme.spacing(191)}
              flexShrink={0}
              position="relative"
              className={tagLabel == 'event' ? styles.eventDate : ''}
            >
              <Typography color="grey.700" variant="h4">
                {formattedDate.split(' ')[0]}
              </Typography>
              <Typography color="grey.700" variant="bodyL">
                {formattedDate.split(' ')[1] + ' ' + formattedDate.split(' ')[2]}
              </Typography>
            </Box>
          )}

          <Box
            height="100%"
            py={theme.spacing(24)}
            display="flex"
            pl={imageUrl ? (isLarger ? 0 : theme.spacing(32)) : theme.spacing(32)}
            flexGrow={1}
            flexDirection="column"
            gap={theme.spacing(10)}
          >
            <Box
              display="flex"
              flexDirection={isLarger ? 'row' : 'column'}
              gap={{ xs: theme.spacing(8), lg: theme.spacing(12) }}
              alignItems="baseline"
            >
              {tagLabel && (
                <Box className={`${styles.TagBox} ${styles.TagBoxStatic}`}>
                  <Typography variant="overlineMono" textTransform="uppercase" fontSize={14} color="secondary.700">
                    {tagLabelText || tagLabel}
                  </Typography>
                </Box>
              )}

              {formattedDate && tagLabel != 'event' && (
                <Typography color="grey.700" variant="caption">
                  {formattedDate}
                </Typography>
              )}

              {source && (
                <Typography color="grey.700" variant="caption">
                  {source}
                </Typography>
              )}
            </Box>

            {title && (
              <Box
                maxHeight={theme.spacing(150)}
                className={styles.ClampedBox}
                overflow="hidden"
                textOverflow="ellipsis"
                flexGrow={2}
              >
                <Typography variant="bodyL" fontWeight="bold" color="common.black">
                  {title.substring(0, 120)}
                  {title.length > 120 ? '...' : ''}
                </Typography>
              </Box>
            )}

            {caption && <DocumentCardCaption {...{ caption, captionFormat }} />}
          </Box>
          <Box display="flex" alignItems="center">
            {tagLabel != 'event' && (
              <Icon
                style={{ fontSize: theme.spacing(32) }}
                sx={{
                  flexGrow: 0,
                  marginRight: { xs: theme.spacing(24), lg: theme.spacing(37) },
                  color: 'primary.main',
                }}
              >
                {iconName}
              </Icon>
            )}
          </Box>
        </Box>
      );

    case 'overlay-with-title-and-photo':
      return (
        <Box
          p={{ xs: 12, lg: 24 }}
          className={`${styles.WallCard} ${styles.Card}`}
          bgcolor="grey.900"
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            flexFlow: 'row nowrap',
            background: 'linear-gradient(232.33deg, #000000 22.27%, #43575D 100.26%)',
            ...sx,
          }}
          zIndex={zIndex}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          position="relative"
          borderRadius={theme.spacing(16)}
        >
          <Typography
            className="font-weight-bold"
            variant={textVariant}
            component="p"
            sx={{ color: 'common.white', maxWidth: 100 }}
          >
            {label}
          </Typography>

          <Box position="absolute" top={46} left={0}>
            <Image src={brandCardBackground} alt={imageAlt || label} />
          </Box>

          <Box
            width={{ xs: 100, lg: 232 }}
            height={{ xs: 100, lg: 232 }}
            position="absolute"
            overflow="hidden"
            borderRadius="50%"
            right={{ xs: -30, lg: -85 }}
            top={{ xs: -10, lg: -65 }}
          >
            <Image
              src={imageUrl}
              alt={imageAlt || label}
              fill
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
            <Box
              position="absolute"
              width="100%"
              height="100%"
              sx={{ background: 'linear-gradient(34.74deg, rgba(0, 0, 0, 0.4) 12.98%, rgba(219, 0, 47, 0.04) 76.17%)' }}
            ></Box>
          </Box>
        </Box>
      );

    case 'background-with-icon':
      return (
        <Box
          pt={{ xs: 12, lg: 22 }}
          px={{ xs: 12, lg: 24 }}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          zIndex={zIndex}
          sx={{
            ...sx,
            '&:hover .card-content': {
              opacity: 1,
              transform: 'translateY(0)',
            },
          }}
          position="relative"
          overflow="hidden"
          className={`${styles.WallCard} ${styles.Card}`}
          borderRadius={theme.spacing(16)}
        >
          <Image
            src={imageUrl}
            alt={imageAlt || label}
            fill
            style={{ objectFit: 'cover', backgroundOrigin: 'content-box', objectPosition: 'center' }}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />

          <Box
            position="absolute"
            top={0}
            left={0}
            display="flex"
            p={theme.spacing(16)}
            alignItems="flex-end"
            width="100%"
            height="100%"
            sx={{ background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.45) 10.27%, rgba(0, 0, 0, 0.05) 57.59%)' }}
          >
            <RoundLabelIcon zIndex={2} position="absolute" top={16} left={16} iconName={iconName} />

            <Stack
              flexGrow={1}
              direction="column"
              color="common.white"
              sx={{
                opacity: 0,
                transform: 'translateY(1rem)',
                transition: 'transform 200ms ease 200ms, opacity 200ms ease 200ms',
              }}
              className="card-content"
              gap={{ xs: theme.spacing(10), lg: theme.spacing(16) }}
            >
              <Typography fontWeight={700} variant="bodyL">
                {label}
              </Typography>
              <EastIcon />
            </Stack>
          </Box>
        </Box>
      );

    case 'carousel-card':
      return (
        <Box position="relative" className={`${styles.CarouselCard} ${styles.Card}`}>
          <Box className={styles.CarouselCardImageBox} overflow="hidden">
            <Image
              src={image.url}
              alt={image.alt}
              fill
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />

            <Box
              position="absolute"
              width="100%"
              height="100%"
              top={0}
              left={0}
              sx={{ background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.45) 10.27%, rgba(0, 0, 0, 0.05) 57.59%)' }}
            />
          </Box>

          {tagLabel && (
            <Box className={styles.TagBox}>
              <Typography color="secondary.700" variant="overlineMono">
                {parseHTML(tagLabel)}
              </Typography>
            </Box>
          )}

          <Box className={styles.CarouselCardContentBox} p={24}>
            <Typography component="small" classes={{ caption: styles.CarouselCardContentBoxDate }} variant="caption">
              {formattedDate}
            </Typography>

            <Typography className="font-weight-bold" component="p" color="grey.900" variant="bodyL">
              {label}
            </Typography>

            <Icon className={styles.CarouselCardArrowIcon}>east</Icon>
          </Box>
        </Box>
      );

    case 'last-items-card':
      return (
        <Box
          position="relative"
          className={`${styles.LastVideosCard} ${styles.Card}`}
          minHeight={isMobile ? theme.spacing(390) : theme.spacing(480)}
        >
          <Box className={styles.LastVideosCardImageBox} overflow="hidden">
            <Image style={{ objectFit: 'cover' }} fill src={image.url} alt={image.alt} />
          </Box>

          <Box
            px={theme.spacing(24)}
            pb={theme.spacing(16)}
            alignContent={'flex-end'}
            zIndex={1}
            width={'100%'}
            sx={{ background: 'linear-gradient(181deg, rgba(31, 31, 31, 0.00) 49.75%, #1F1F1F 98.79%)' }}
          >
            <Typography component="p" color="white" variant="bodyM" fontWeight={700} mb={theme.spacing(10)}>
              {label}
            </Typography>

            <Box
              className="video"
              borderRadius="50%"
              zIndex={1}
              width={{ xs: theme.spacing(44), lg: theme.spacing(56) }}
              height={{ xs: theme.spacing(44), lg: theme.spacing(56) }}
              display="flex"
              bgcolor="alphaRed.80"
              alignItems="center"
              justifyContent="center"
            >
              <Icon sx={{ color: 'common.white' }}>play_arrow</Icon>
            </Box>
          </Box>
        </Box>
      );

    case 'explore-videos-card':
      return (
        <ExploreVideosCard
          index={index}
          label={label}
          imageAlt={image.alt}
          imageUrl={image.url}
          tags={tags}
          path={path}
          videoUrl={videoUrl}
          abstract={abstract}
        />
      );

    case 'magazine-card':
      return (
        <Box position="relative" className={`${styles.CarouselCard} ${styles.Card} ${styles.magazineCard}`}>
          <Box className={styles.CarouselCardImageBox} overflow="hidden">
            <Image
              src={imageUrl}
              alt={imageAlt || label}
              fill
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />

            <Box
              position="absolute"
              width="100%"
              height="100%"
              top={0}
              left={0}
              sx={{ background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.45) 10.27%, rgba(0, 0, 0, 0.05) 57.59%)' }}
            />
          </Box>

          <Image
            src={logoWBMagazine.src}
            alt={'logo WB Magazine'}
            width={82}
            height={41}
            style={{ position: 'absolute', top: theme.spacing(24), right: theme.spacing(24) }}
          />

          <Box className={styles.CarouselCardContentBox} p={24} color="common.white">
            <Typography component="small" classes={{ caption: styles.CarouselCardContentBoxDate }} variant="caption">
              {formattedDate}
            </Typography>

            <Typography className="font-weight-bold" component="p" variant="bodyL">
              {label}
            </Typography>

            <Icon className={`${styles.CarouselCardArrowIcon} ${styles.magazineArrowIcon}`}>north_east</Icon>
          </Box>
        </Box>
      );

    case 'with-background-title-and-forward-arrow':
      return (
        <Box className={`${styles.FeaturedProjectItem} ${styles.Card}`} sx={sx} onClick={onClick}>
          {imageUrl && (
            <Image
              src={imageUrl}
              alt={imageAlt || label}
              fill
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          )}
          <Box
            justifyContent="flex-end"
            flexDirection="column"
            flexWrap="nowrap"
            position="relative"
            p={theme.spacing(overlayBoxPadding)}
            display="flex"
            className={styles.FeaturedProjectItemOverlay}
          >
            {iconName && (
              <RoundLabelIcon
                variant="large"
                position="absolute"
                top={theme.spacing(overlayBoxPadding)}
                iconName={iconName}
              />
            )}
            {businessIcon?.imageUrl && (
              <Image
                className="businessIcon"
                src={businessIcon.imageUrl}
                alt={businessIcon.imageAlt}
                width={80}
                height={80}
                style={{ position: 'absolute', top: theme.spacing(24), left: theme.spacing(24) }}
              />
            )}
            <Typography variant="bodyL" sx={{ fontWeight: 700, color: 'common.white' }}>
              {label}
            </Typography>

            {caption && (
              <Typography
                component="p"
                color="grey.400"
                mt={{ xs: 4, lg: 8 }}
                textTransform="uppercase"
                variant="caption"
              >
                {caption}
              </Typography>
            )}

            {isExternalLink ? (
              <NorthEastIcon sx={{ mt: { xs: 16, lg: 18 }, color: 'common.white' }} />
            ) : (
              <EastIcon sx={{ mt: { xs: 16, lg: 18 }, color: 'common.white' }} />
            )}
          </Box>
        </Box>
      );

    case 'video-card':
      return (
        <Box
          borderRadius={theme.spacing(16)}
          minHeight={theme.spacing(216)}
          overflow="hidden"
          display="flex"
          className={`${styles.Card}`}
          alignItems="center"
          justifyContent="center"
          position="relative"
          sx={{ ':hover': { '> .video': { backgroundColor: 'alphaRed.90' } }, ...sx }}
        >
          <Image
            src={imageUrl}
            alt={imageAlt || label}
            fill
            style={{ objectFit: 'cover' }}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />

          <Box
            width="100%"
            height="100%"
            position="absolute"
            sx={{ background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.02) 100%)' }}
          />

          <Box
            className="video"
            borderRadius="50%"
            zIndex={1}
            width={{ xs: theme.spacing(44), lg: theme.spacing(56) }}
            height={{ xs: theme.spacing(44), lg: theme.spacing(56) }}
            display="flex"
            bgcolor="alphaRed.80"
            alignItems="center"
            justifyContent="center"
          >
            <Icon sx={{ color: 'common.white' }}>play_arrow</Icon>
          </Box>
        </Box>
      );

    case 'podcast-card':
      return (
        <Box
          minHeight={theme.spacing(216)}
          className={`${styles.Card}`}
          borderRadius={theme.spacing(16)}
          padding={theme.spacing(24, 24, 16)}
          color="common.white"
          sx={{
            position: 'relative',
            background: 'linear-gradient(180deg, #404040 0%, #43575D 100%)',
            ...sx,
          }}
        >
          <Image fill style={{ objectFit: 'contain', objectPosition: 'bottom' }} src={audioLevel} alt={caption} />

          <Stack height="100%" justifyContent="space-between" direction="column">
            <Box>
              {tagLabel && (
                <Box className={styles.CarouselCardTag}>
                  <Typography classes={{ overline: styles.CarouselCardOverline }} variant="overlineMono">
                    {tagLabel}
                  </Typography>
                </Box>
              )}

              <Typography mt={theme.spacing(8)} variant="caption" component="p">
                {caption}
              </Typography>

              <Typography
                className="font-weight-bold"
                mt={theme.spacing(8)}
                pb={theme.spacing(77)}
                variant="bodyL"
                component="p"
              >
                {title}
              </Typography>
            </Box>
            <EastIcon sx={{ position: 'absolute', bottom: theme.spacing(16), left: theme.spacing(24) }} />
          </Stack>
        </Box>
      );

    case 'podcast-link-card':
      return (
        <Box
          borderRadius={theme.spacing(16)}
          padding={theme.spacing(20, 22, 20, 24)}
          color="common.white"
          maxWidth={theme.spacing(496)}
          bgcolor={colorVariant === 'dark' ? 'alphaWhite.30' : 'common.black'}
          sx={{
            position: 'relative',
            boxShadow: 'none',
            transition: 'box-shadow 0.3s linear',
            '&:hover': {
              boxShadow:
                colorVariant === 'dark' ? theme.boxShadows.elevationDark200 : theme.boxShadows.elevationLight800,
            },
            ...sx,
          }}
        >
          <Image fill style={{ objectFit: 'contain', objectPosition: 'bottom' }} src={audioLevelLarge} alt={title} />

          <Box>
            <Typography variant="caption" component="p" mb={theme.spacing(16)}>
              {caption}
            </Typography>
            <Box display="flex" justifyContent="space-between" gap={{ xs: theme.spacing(24), lg: theme.spacing(28) }}>
              <Typography variant="bodyLBold" component="p">
                {title}
              </Typography>
              <EastIcon />
            </Box>
          </Box>
        </Box>
      );

    case 'with-image-center-title-and-link':
      return (
        <Box position="relative" className={`${styles.Card}`} height={440} borderRadius={6} overflow="hidden">
          <Image
            src={imageUrl}
            alt={imageAlt || title}
            fill
            style={{ objectFit: 'cover' }}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />

          <Stack
            px={{ xs: 0, lg: 59 }}
            justifyContent="center"
            alignItems="center"
            position="absolute"
            width="100%"
            textAlign="center"
            height="100%"
            sx={{ background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.9) 100%)' }}
            spacing={16}
            p={theme.spacing(overlayBoxPadding)}
          >
            <Typography variant="h4" sx={{ color: 'common.white' }}>
              {title}
            </Typography>

            <CustomButton
              color="link"
              variant="link"
              sx={{ color: 'common.white' }}
              endIcon={<EastIcon sx={{ color: 'common.white' }} />}
              text={label}
            />
          </Stack>
        </Box>
      );

    case 'sdg-card':
      return (
        <Box
          p={{ xs: 22, lg: 30 }}
          sx={sx}
          bgcolor="common.white"
          zIndex={zIndex}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          className={`${styles.Card}`}
          border="1px solid"
          borderColor="secondary.50"
          borderRadius={4}
          height={{ xs: theme.spacing(188), lg: theme.spacing(216) }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            position="relative"
            height={{ xs: theme.spacing(144), lg: theme.spacing(150) }}
            width={{ xs: theme.spacing(144), lg: theme.spacing(150) }}
          >
            <Image
              src={imageUrl}
              alt={imageAlt || 'sdg'}
              fill
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          </Box>
        </Box>
      );

    case 'sdg-large-card':
      return (
        <Box
          p={{ xs: 16, lg: 32 }}
          bgcolor="common.white"
          zIndex={zIndex}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          borderRadius={6}
          display="flex"
          alignItems="center"
          gap={{ xs: theme.spacing(16), lg: theme.spacing(32) }}
          minWidth={{ xs: theme.spacing(316), lg: theme.spacing(513) }}
          sx={{
            background: 'linear-gradient(180deg, #E3E9EB -125.33%, #FFFFFF 100%); , white',
            ...sx,
          }}
        >
          <Box position="relative" flexShrink={0} height={theme.spacing(88)} width={theme.spacing(88)}>
            <Image
              src={imageUrl}
              alt={imageAlt || 'sdg'}
              fill
              style={{ objectFit: 'cover' }}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={{ xs: theme.spacing(8), lg: theme.spacing(3.5) }}>
            <Typography variant="overline" letterSpacing={0}>
              {caption}
            </Typography>
            <Typography variant="bodyS">{title}</Typography>
          </Box>
        </Box>
      );

    case 'ratings-card':
      return (
        <Box
          py={theme.spacing(33)}
          bgcolor="common.white"
          zIndex={zIndex}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          className={`${styles.Card}`}
          border="1px solid"
          borderColor="secondary.50"
          borderRadius={4}
          height={{ xs: theme.spacing(188), lg: theme.spacing(216) }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            background: 'linear-gradient(180deg, #D9E2E4 -50.93%, rgba(255, 255, 255, 0.1) 105.56%) , white',
            ...sx,
          }}
        >
          <Box
            position="relative"
            height={{ xs: theme.spacing(120), lg: theme.spacing(150) }}
            width={{ xs: theme.spacing(160), lg: theme.spacing(200) }}
          >
            <Image
              src={imageUrl}
              alt={imageAlt || 'rating'}
              fill
              style={{ objectFit: 'contain' }}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
          </Box>
        </Box>
      );

    case 'padcast-platform-card':
      return (
        <Box
          pt={{ xs: 16, lg: 24 }}
          px={{ xs: 16, lg: 24 }}
          pb={{ xs: 16, lg: 24 }}
          sx={sx}
          bgcolor="common.white"
          zIndex={zIndex}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          className={`${styles.WallCard} ${styles.Card}`}
          border="1px solid"
          borderColor="grey.200"
          borderRadius={2}
        >
          <Box
            component={'span'}
            className={styles.padcastPlatformIcon}
            width={{ xs: theme.spacing(32), lg: theme.spacing(72) }}
            height={{ xs: theme.spacing(32), lg: theme.spacing(72) }}
            display="inline-flex"
            mt={{ lg: theme.spacing(8) }}
            ml={{ lg: theme.spacing(8) }}
          >
            {platformIcons[podcastIcon]}
          </Box>

          <Typography
            mt={{ xs: 16, lg: 55 }}
            component="p"
            className="font-weight-bold"
            color="common.black"
            variant={textVariant}
          >
            {label}
          </Typography>
        </Box>
      );
  }
};

export default CustomCard;
