import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { sanitize } from 'isomorphic-dompurify';

export const parseHTML = (html: string, options?: HTMLReactParserOptions) => {
  if (!html) return;

  return parse(
    sanitize(html, {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'],
    }),
    options
  );
};
