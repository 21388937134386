import { SocialProps } from '@atoms/SocialIcon/Social.props';
import InsertLink from '@mui/icons-material/InsertLink';
import LinkedInVector from '@atoms/CustomVectors/LinkedinVector';
import TwitterVector from '@atoms/CustomVectors/TwitterVector';
import FacebookVector from '@atoms/CustomVectors/FacebookVector';
import theme from '@mui/theme';

const iconSx = { width: theme.spacing(24), height: theme.spacing(24) };

const SocialIcon = (props: SocialProps) => {
  switch (props.name) {
    case 'linkedin':
      return <LinkedInVector sxOverride={iconSx} />;
    case 'twitter':
      return <TwitterVector sxOverride={iconSx} />;
    case 'facebook':
      return <FacebookVector sxOverride={iconSx} />;
    default:
      return <InsertLink sx={iconSx} />;
  }
};

export default SocialIcon;
