import { Box, Modal, Typography, Icon, Popover, useMediaQuery } from '@mui/material';
import theme from '@mui/theme';
import Image from 'next/image';
import { CustomCardProps } from '../../CustomCard.props';
import styles from '@atoms/CustomCard/CustomCard.module.scss';
import { useState } from 'react';
import { useSocials } from '@hooks/useSocials';
import ExpandVideoContent from '../../../ExpandVideoContent/ExpandVideoContent';

const ExploreVideosCard = (props: CustomCardProps) => {
  const { index, label, imageAlt, imageUrl, tags, path, videoUrl, abstract } = props;

  const [hoveringCard, setHoveringCard] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });

  const expandCard = Boolean(anchorEl);

  const [showMobileModal, setShowMobileModal] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [isMuted, setIsMuted] = useState(false);

  const handleHoverCard = (event) => {
    setHoveringCard(true);
  };

  const handleHoverLeaveCard = () => {
    setHoveringCard(false);
  };

  const handleExpandCard = (event) => {
    event.preventDefault();
    setHoveringCard(false);
    setAnchorEl(event.currentTarget);

    const rect = event.currentTarget.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const popoverWidth = 600;

    let calculatedLeft = rect.left;
    if (rect.left + popoverWidth > viewportWidth) {
      calculatedLeft = viewportWidth - popoverWidth;
    }
    setPopoverPosition({
      top: rect.bottom,
      left: calculatedLeft,
    });
  };

  const handleCloseExpandCard = () => {
    setHoveringCard(false);
    setAnchorEl(null);
  };

  const handleClickOnPopover = (event) => {
    event.preventDefault();
  };

  const handleCloseMobileModal = (event) => {
    event.preventDefault();
    setHoveringCard(false);
    setShowMobileModal(false);
  };

  const socials = useSocials({
    title: label || '',
    url: videoUrl || '',
  });

  const handleClickOnCardMobile = (event) => {
    event.preventDefault();
    setHoveringCard(false);
    setShowMobileModal(true);
  };

  const toggleMute = () => {
    setIsMuted((prevMuted) => !prevMuted);
  };

  return (
    <Box>
      <Box>
        <Box
          position="relative"
          className={
            isMobile ? `${styles.ExploreVideosCardMobile} ${styles.Card}` : `${styles.ExploreVideosCard} ${styles.Card}`
          }
          minHeight={isMobile ? theme.spacing(207) : theme.spacing(247)}
          onMouseEnter={handleHoverCard}
          onMouseLeave={handleHoverLeaveCard}
          onClick={isMobile ? handleClickOnCardMobile : null}
        >
          <Box className={styles.ExploreVideosCardImageBox} overflow="hidden">
            <Image style={{ objectFit: 'cover' }} fill src={imageUrl} alt={imageAlt} />
          </Box>
          <Box
            px={theme.spacing(24)}
            pb={theme.spacing(16)}
            alignContent={'flex-end'}
            zIndex={0}
            width={'100%'}
            sx={{ background: 'linear-gradient(181deg, rgba(31, 31, 31, 0.00) 49.75%, #1F1F1F 98.79%)' }}
          >
            <Typography component="p" color="white" variant="bodyM" fontWeight={700}>
              {label}
            </Typography>
          </Box>
          <Box
            className={styles.ExploreVideosCardHovering}
            zIndex={1}
            padding={theme.spacing(16)}
            position={'absolute'}
            bottom={theme.spacing(-247)}
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            sx={{
              backgroundColor: theme.palette.grey['900'],
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Box
                className="video"
                borderRadius="50%"
                zIndex={1}
                width={theme.spacing(24)}
                height={theme.spacing(24)}
                display="flex"
                bgcolor="alphaRed.80"
                alignItems="center"
                justifyContent="center"
              >
                <Icon sx={{ fontSize: `${theme.spacing(12)} !important`, color: 'common.white' }}>play_arrow</Icon>
              </Box>
              <Box
                borderRadius="50%"
                border={`2px solid #fff`}
                width={theme.spacing(24)}
                height={theme.spacing(24)}
                alignItems="center"
                justifyContent="center"
                display={'flex'}
                onClick={handleExpandCard}
              >
                <Icon sx={{ fontSize: `${theme.spacing(12)} !important`, color: 'common.white' }}>
                  keyboard_arrow_down
                </Icon>
              </Box>
            </Box>
            <Box sx={{ marginTop: theme.spacing(12) }}>
              <Typography component="p" color="white" variant="bodyS" fontWeight={700}>
                {label}
              </Typography>
            </Box>
            {tags &&
              tags.map((tag, index) => (
                <Box key={index + tag} sx={{ textTransform: 'uppercase', color: theme.palette.grey['500'] }}>
                  <Typography
                    key={index}
                    component="span"
                    variant="caption"
                    sx={{ marginLeft: theme.spacing(4) }}
                    fontSize={theme.spacing(10)}
                  >
                    {`#${tag}`}
                  </Typography>
                </Box>
              ))}
          </Box>

          {/* POPOVER PER DESKTOP */}
          <Popover
            id="expand-card-popover"
            open={expandCard}
            anchorReference="anchorPosition"
            anchorPosition={popoverPosition}
            onClose={handleCloseExpandCard}
            anchorEl={anchorEl}
            onClick={handleClickOnPopover}
            anchorOrigin={{
              vertical: 'center',
              horizontal: -40,
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            sx={{
              '& .MuiPopover-paper': {
                borderRadius: `${theme.spacing(8)} ${theme.spacing(8)} ${theme.spacing(0)} ${theme.spacing(0)}`,
                minHeight: isMobile ? '100%' : 'fit-content',
                minWidth: isMobile ? '100%' : theme.spacing(808),
                maxWidth: theme.spacing(1000),
                top: popoverPosition.top,
                left: popoverPosition.left,
                backgroundColor: theme.palette.grey['900'],
              },
              '& .MuiModal-backdrop': {
                backgroundColor: theme.palette.alphaBlack['60'],
              },
            }}
          >
            <ExpandVideoContent
              component={'popover'}
              videoUrl={videoUrl}
              imageUrl={imageUrl}
              imageAlt={imageAlt}
              isMuted={isMuted}
              toggleMute={toggleMute}
              handleCloseExpandCard={handleCloseExpandCard}
              handleCloseMobileModal={handleCloseMobileModal}
              label={label}
              path={path}
              socials={socials}
              abstract={abstract}
              tags={tags}
            />
          </Popover>

          {/* MODALE PER MOBILE */}
          <Modal
            open={showMobileModal}
            onClose={handleCloseMobileModal}
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'black',
            }}
          >
            <ExpandVideoContent
              component={'modal'}
              videoUrl={videoUrl}
              imageUrl={imageUrl}
              imageAlt={imageAlt}
              isMuted={isMuted}
              toggleMute={toggleMute}
              handleCloseExpandCard={handleCloseExpandCard}
              handleCloseMobileModal={handleCloseMobileModal}
              label={label}
              path={path}
              socials={socials}
              abstract={abstract}
              tags={tags}
            />
          </Modal>
        </Box>
      </Box>
    </Box>
  );
};

export default ExploreVideosCard;
