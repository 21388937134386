import styles from './MediaPlayerCard.module.scss';
import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Box, IconButton } from '@mui/material';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeOff from '@mui/icons-material/VolumeOff';
import Image from 'next/image';
import theme from '@mui/theme';
import useIsIos from '@hooks/useIsIos';

const MediaPlayerCard = ({ videoUrl, imageUrl, imageAlt }) => {
  const { isIos } = useIsIos();
  const playerRef = useRef(null);

  const [isMuted, setIsMuted] = useState(() => {
    if (isIos) return true;
    const storedMuteState = sessionStorage.getItem('isMuted');
    return storedMuteState === 'true';
  });

  const toggleMute = () => {
    setIsMuted((prevMuted) => {
      const newMutedState = !prevMuted;
      sessionStorage.setItem('isMuted', newMutedState.toString());

      if (playerRef.current) {
        playerRef.current.muted = newMutedState;
      }
      return newMutedState;
    });
  };

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.muted = isMuted;
    }
  }, [isMuted]);

  return (
    <>
      {!videoUrl ? (
        <Box className={styles.CarouselCardImageBox} overflow="hidden" minHeight={theme.spacing(420)}>
          <Image
            style={{ objectFit: 'cover' }}
            className={styles.modalImageExploreCard}
            fill
            src={imageUrl}
            alt={imageAlt}
          />
        </Box>
      ) : (
        <>
          <Box className={styles.contYoutubeVideo}>
            <Box className={styles.overIframe} />
            <ReactPlayer
              ref={playerRef}
              className="react-player"
              url={videoUrl}
              width="100%"
              height={480}
              playing={true}
              muted={isMuted}
              controls={false}
              loop={true}
              config={{
                youtube: {
                  playerVars: {
                    showinfo: 0,
                    modestbranding: 1,
                    rel: 0,
                    controls: 0,
                    autoplay: 1,
                    mute: isMuted ? 1 : 0,
                  },
                },
              }}
            />
          </Box>
          <IconButton onClick={toggleMute} className={styles.iconMuteButton}>
            {isMuted ? <VolumeOff /> : <VolumeUp />}
          </IconButton>
        </>
      )}
    </>
  );
};

export default MediaPlayerCard;
